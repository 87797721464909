import React from 'react'

type HasChangedContextType = {
    hasChanged: boolean,
    setHasChanged: (hasChanged: boolean) => void
}

export const HasChangedContext = React.createContext<HasChangedContextType>({
    hasChanged: false,
    setHasChanged: () => { }
})

export default function HasChangedProvider({ children }: { children: React.ReactNode}) {
    const [hasChanged, setHasChanged] = React.useState(false)



  return (
    <HasChangedContext.Provider value={{ hasChanged, setHasChanged }}>
      {children}
    </HasChangedContext.Provider>
  )
}
