import styles from './FutureFixtures.module.scss'
import { Fixture, Team } from '../../../../domain/model'
import { useContext, useState } from 'react';
import { FDRContext } from '../../Hooks/FDRContext';
import { useDetermineDifficulty } from '../../Hooks/useDetermineDifficulty';

type FutureFixturesProps = {
    fixtures: Fixture[];
    fromEvent: number;
    toEvent: number;
    team: Team,
    fdr: "attack" | "defense",
    dontIncludeName?: boolean
}

function FutureFixtures({ fixtures, fromEvent, toEvent, team, fdr, dontIncludeName }: FutureFixturesProps) {
    const fplStratDifficulties: { [key: number]: string } = {
        1: styles.one,
        2: styles.two,
        3: styles.three,
        4: styles.four,
        5: styles.five,
        6: styles.six,
        7: styles.seven,
        8: styles.eight,
        9: styles.nine,
        10: styles.ten,
    }

    const standardDifficulties: { [key: number]: string } = {
        1: styles.veryEasy,
        2: styles.easy,
        3: styles.medium,
        4: styles.hard,
        5: styles.veryHard,
    }

    const { fdrSetting, teams } = useContext(FDRContext)

    const { determineDifficulty } = useDetermineDifficulty()


    function renderNextFixtures() {

        const fixedToEvent = toEvent

        const nextXEvents = [...Array(fixedToEvent - fromEvent).keys()].map(i => fromEvent + i)
       
        return nextXEvents.map(event => {
            const fixturesOfEvent = fixtures.filter(f => (f.team_h.id === team.id || f.team_a.id === team.id) && f.event.id === event)

            // for each fixture of the event, create a div with the team name and the difficulty

            const divs = fixturesOfEvent.map(f => {
                const text = f.team_h.id === team.id ? `${f.team_a.short_name}` : `${f.team_h.short_name.toLowerCase()}`
                const difficulty = determineDifficulty(team, f, fdr)

                const eventClassName = `${styles.future} ${fdrSetting === "fplstrat" ? fplStratDifficulties[difficulty] : standardDifficulties[difficulty]}`
                return <div className={eventClassName} key={`${f.team_h.id} ${f.team_a.id}`}>{dontIncludeName ? "" : text}</div>
            })



            return fixturesOfEvent.length > 0 ? (
                <div className={styles.futureFixtureContainer} key={event}>
                    {divs}
                </div>
            ) : (
                <div className={styles.futureFixtureContainer} key={event}>
                    <div className={`${styles.future} ${styles.blank}`}>---</div>
                </div>
            )


        })


    }

    return renderNextFixtures()


}


export default FutureFixtures;