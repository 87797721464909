import React, { useEffect } from "react";
import { Team } from "../Components/TransferPlanner/TransferPlanner";

type FDRContextType = {
    teams: Team[],
    setTeams: (teams: Team[]) => void
    fdrSetting: "standard" | "fplstrat" | "custom",
    showTeamNames: boolean,
    setShowTeamNames: (show: boolean) => void
    setFDRSetting: (setting: "standard" | "fplstrat" | "custom") => void
}

export const FDRContext = React.createContext<FDRContextType>({
    teams: [],
    setTeams: () => { },
    fdrSetting: "standard",
    showTeamNames: true,
    setShowTeamNames: () => { },
    setFDRSetting: () => { }
})

export default function FDRProvider({ children }: { children: React.ReactNode }) {
    const stored = localStorage.getItem("fdrSetting")
    const storedShowTeamNames = localStorage.getItem("showTeamNames")

    const [teams, setTeams] = React.useState<Team[]>([])
    const [fdrSetting, setFDRSetting] = React.useState<"standard" | "fplstrat" | "custom">(stored ? stored as "standard" | "fplstrat" | "custom" : "standard")

    const [showTeamNames, setShowTeamNames] = React.useState<boolean>(storedShowTeamNames ? storedShowTeamNames === "true" : false)


    useEffect(() => {
        localStorage.setItem("fdrSetting", fdrSetting)
    }, [fdrSetting])

    useEffect(() => {
        localStorage.setItem("showTeamNames", showTeamNames.toString())
    }, [showTeamNames])

    return (
        <FDRContext.Provider value={{ teams, setTeams, fdrSetting, setFDRSetting, showTeamNames, setShowTeamNames }}>
            {children}
        </FDRContext.Provider>
    )
}