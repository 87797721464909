import React, { createContext, ReactNode } from "react";
import { Toast } from "../Components/Toast/Toast";



type ToastContextType = {
    createToast: (toastType: "positive" | "negative" | "neutral", msg: string) => void,
}

export const ToastContext = createContext<ToastContextType>({
    createToast: () => <div></div>
})

type ToastType = {
    toastType: "positive" | "negative" | "neutral",
    msg: string,
    id: number
}

export default function ToastProvider({ children }: { children: React.ReactNode }) {
    const [toasts, setToasts] = React.useState<ToastType[]>([])


    const createToast = (toastType: "positive" | "negative" | "neutral", msg: string) => {
        const id = Math.floor(Math.random() * 1000000)
        setToasts([...toasts, { toastType, msg, id }])

        setTimeout(() => {
            removeToast(id)
        }, 8000)
    }

    const removeToast = (id: number) => {
        setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id))
    }


    return (
        <ToastContext.Provider value={{ createToast }}>
            <div className="toasts">
                {toasts.map((toast, index) => {
                    return <Toast key={index} toastType={toast.toastType} msg={toast.msg} id={toast.id} closeWindowFunction={removeToast} />
                })}
            </div>
            {children}

        </ToastContext.Provider>
    )
}