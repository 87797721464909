import React, { useState } from "react";
import { Plan } from "../../../../domain/model";
import styles from "./SelectPlan.module.scss";
import { deletePlanLocally, getPlan, getPlanLocally } from "../../fpl/api";
import { deletePlan } from "../../fpl/api";

import loadingSpinner from "./loading.svg";
import closeBtn from "./close.svg";
import { AuthContext } from "../../Hooks/AuthContext";
import { Link } from "react-router-dom";

type SelectPlanProps = {
    planList: Plan[],
    setPlan: Function,
    setPlanList: Function,
    updatePlayers: Function
}

export function SelectPlan({ planList, setPlan, setPlanList, updatePlayers }: SelectPlanProps) {

    const [gettingPlan, setGettingPlan] = useState(0);

    const { isAuthenticated, csrfToken, emailVerified } = React.useContext(AuthContext);

    async function handleSelectPlan(planId: number) {



        setGettingPlan(planId);
        //const plan = await getPlan(planId)

        const plan = isAuthenticated ? await getPlan(planId, csrfToken) : getPlanLocally(planId)
        if (!plan) {
            throw new Error("Plan not found")
        }
        if (!isAuthenticated) {
            await updatePlayers(plan)
        }
        setPlan(plan);

        setGettingPlan(0);
    }

    async function handleDeletePlan(planId: number) {

        const confirmDelete = confirm("Are you sure you want to delete this plan?")

        if (!confirmDelete) {
            return
        }

        if (!isAuthenticated) {
            deletePlanLocally(planId)
            setPlanList(planList.filter(p => p.id !== planId));
        }

        if (isAuthenticated) {
            deletePlan(planId, csrfToken)
                .then(() => {
                    setPlanList(planList.filter(p => p.id !== planId));
                })
        }
    }

    function getSubHeading() {
        if (!isAuthenticated && planList.length > 0) {
            return (
                <h5 className={styles.subHeading}><Link to={"/register"}>sign up</Link> to create up to 10 plans and sync your plans across devices!</h5>
            )
        } else if (planList.length === 0) {
            return (
                <h5 className={styles.subHeading}>no plans saved!</h5>
            )
        } else if (isAuthenticated && !emailVerified) {
            return (
                <h5 className={styles.subHeading}>{planList.length}/2 saved plans. Verify your email to save up to 10. Didn't get an email?<Link to={"/manage-account"}> Click here</Link></h5>

            )

        } else if (isAuthenticated && planList.length > 0) {
            return (
                <h5 className={styles.subHeading}>{planList.length}/10 saved plans</h5>
            )
        }
    }

    return (

        <div className={styles.selectPlan}>

            <h1 className={styles.selectClassHeading}>Select Plan</h1>

            {getSubHeading()}


            <ul className={styles.savedPlansList}>

                {
                    planList.map(p => {
                        return (
                            <li className={styles.selectTableButtons} key={p.id}>
                                <div onClick={() => handleSelectPlan(p.id)} className={styles.listedPlan}>
                                    {gettingPlan === p.id ? (<img className={styles.spinner} src={loadingSpinner}></img>) : <p>{p.name}</p>}
                                    <img src={closeBtn} alt="" onClick={(e) => { e.stopPropagation(); handleDeletePlan(p.id) }} className={styles.deleteButton} />
                                </div>



                            </li>
                        )
                    })
                }

            </ul>

        </div>





    )
}