import React, { useContext, useEffect } from 'react'
import styles from './ManageAccountPage.module.scss'
import { Helmet } from 'react-helmet'
import { Header } from '../Header/Header'
import Footer from '../Footer/Footer'
import { AuthContext } from '../../Hooks/AuthContext'
import { deleteUser } from '../../fpl/api'

export default function ManageAccountPage() {

  const { email, userId, csrfToken, logOut, emailVerified } = useContext(AuthContext)
  const [deleting, setDeleting] = React.useState(false)
  const [error, setError] = React.useState("")





  async function handleDeleteAccount() {

    const confirmation = prompt("are you sure you want to delete your account? type 'delete' to confirm")
    if (confirmation === "delete") {
      setDeleting(true)
      try {
        const success = await deleteUser(csrfToken, userId)
        if (!success) {
          setError("error deleting account")
        } else {
          window.location.href = "/"

        }
      } catch (e) {
        setError("error deleting account")

      }
      setDeleting(false)
    }
  }

  return (
    <section className={styles.main}>
      <Helmet>
        <title>fplstrat.app | manage account</title>
      </Helmet>

      <Header notInPlan={true} />

      <section className={styles.manageAccount}>
        <section className={styles.manageAccountSection}>
          <h2>Manage Account</h2>
          <p className={styles.subtitle}>logged in as {email}</p>
          <button onClick={logOut} className={styles.logoutBtn}>
            log out
          </button>
        </section>

        <section className={styles.manageAccountSection}>
          <h2>Email Preferences</h2>
          {!emailVerified && <RequestNewVerificationEmail />}

          <EmailPreferencesForm />
        </section>

        <section className={styles.manageAccountSection}>
          <h2>Delete Account</h2>
          <p className={styles.subtitle}>press delete account to permanently delete your account and all associated data</p>
          <button onClick={handleDeleteAccount} className={styles.deleteBtn}>{deleting ? "deleting account ..." : "delete account"}</button>
          {error && <p className={styles.error}>{error}</p>}
        </section>


      </section>


      <Footer />
    </section>
  )
}

function RequestNewVerificationEmail() {
  const { csrfToken } = useContext(AuthContext)
  const [requesting, setRequesting] = React.useState<null | boolean>(null)
  const [error, setError] = React.useState("")

  async function handleRequestNewEmail() {
    setRequesting(true)
    try {
      const response = await fetch("/api/auth/send-verification-mail", {
        method: "GET",
        headers: {
          "csrf-token": csrfToken
        }
      })
      if (response.ok) {
        setRequesting(false)
        return
      } else {
        const data = await response.json()
        setError(data.error)
      }

      setRequesting(false)
    } catch {
      setError("error sending email")
      setRequesting(false)
    }
  }

  return <p className={styles.subtitle}>
    please verify your email! didn't get a link?
    <button className={styles.logoutBtn} onClick={() => handleRequestNewEmail()}>
      {(requesting !== true) ? "send new email" : "sending email..."}
    </button>

    <br />
    {error && <span className={styles.error}>{error}</span>}
    {requesting === false && !error && <span className={styles.success}>email sent!</span>}
  </p>
}


function EmailPreferencesForm() {
  const { csrfToken, emailVerified } = useContext(AuthContext)
  const [emailpreferences, setEmailPreferences] = React.useState<null | boolean>(null)
  const [error, setError] = React.useState("")
  const [isSubmitting, setIsSubmitting] = React.useState<null | boolean>(null)

  useEffect(() => {
    getEmailPreferences()
  }, [])


  async function getEmailPreferences() {
    try {
      fetch("/api/auth/emailpreferences", {
        method: "GET",
        headers: {
          "csrf-token": csrfToken
        }
      }).then(async response => {
        if (response.ok) {
          const data = await response.json()
          setEmailPreferences(data.emailpreferences)
          return
        }
        setError("error getting email preferences")
      })
    } catch {
      setError("could not fetch preferences")
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailPreferences(!emailpreferences)
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      setIsSubmitting(true)
      fetch("/api/auth/emailpreferences", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "csrf-token": csrfToken
        },
        body: JSON.stringify({ emailpreferences })
      }).then(async response => {
        if (response.ok) {
          setIsSubmitting(false)
          return
        }
        setError("error saving preferences")
        setIsSubmitting(false)
      })
    } catch {
      setError("could not save preferences")
      setIsSubmitting(false)
    }

  }

  return (
    <form className={styles.emailPreferencesForm} onSubmit={handleSubmit}>

      <p className={styles.subtitle}>i want to receive emails about: </p>
      {emailpreferences === null && <p className={styles.getting}>getting preferences... </p>}
      {emailpreferences !== null &&
        <div>
          <input type="checkbox" id="emailpreferences" name="emailpreferences" checked={emailpreferences} onChange={handleChange} />
          <label className={styles.emailCheckboxLabel} htmlFor="emailpreferences">fplstrat.app news, feature updates</label>
        </div>
      }


      <button>{isSubmitting ? "saving ..." : "save"}</button>
      {isSubmitting === false && error === "" && <p className={styles.success}>preferences saved!</p>}
      {error && <p className={styles.error}>{error}</p>}
    </form>
  )
}