import React from 'react'
import styles from './Header.module.scss'
import { SavedAlert } from '../SavedAlert/SavedAlert'
import logoSvg from "./logo.svg"
import accountSvg from "./account.svg"
import { Plan } from '../../../../domain/model'

import { Link } from 'react-router-dom'

import { AuthContext } from '../../Hooks/AuthContext'
import { useLocation } from 'react-router-dom'

type HeaderProps = {
    plan?: Plan | null | undefined,
    planList?: Plan[] | undefined,
    handlePlanSelect?: (planId: number, plan: Plan) => Promise<void>,
    handleCreateNewPlan?: () => void,
    notInPlan?: boolean
}

export function Header({ plan, planList, handlePlanSelect, handleCreateNewPlan, notInPlan }: HeaderProps) {
    const { isAuthenticated, logOut, isAuthenticating } = React.useContext(AuthContext)
    const location = useLocation();
    const displayLogin = (location.pathname === '/planner' || location.pathname === '/login' || location.pathname === '/register' || location.pathname === "/manage-account")
    return (
        <header className={notInPlan ? `${styles.header}` : `${styles.header} ${styles.noPadding}`}>
            <span className={styles.title}><img alt='logo' src={logoSvg}></img>fplstrat.app</span>


            {
                ((plan !== null && plan !== undefined && planList && handlePlanSelect)) &&

                <select value={plan.id} onChange={(e) => handlePlanSelect(Number(e.target.value), plan)} className={styles.planSelect} name="plan" id="planSelect">
                    {planList.map(listedPlan => <option key={listedPlan.id} value={listedPlan.id}>{listedPlan.name}</option>)}
                </select>
            }

            {
                (plan && handleCreateNewPlan) &&
                <>
                    <button className={`${styles.addBtn} plausible-event-name=click+add+plan`} onClick={() => handleCreateNewPlan()}>+</button>

                </>
            }

            {location.pathname === "/manage-account" && <Link className={styles.loginBtn} to="/planner">planner</Link>}

            {
                (notInPlan && !isAuthenticated && displayLogin && !isAuthenticating) ? (
                    <>
                        <Link to="/login" className={styles.link}><button className={styles.loginBtn}>log in</button></Link>
                        <Link to="/register" className={styles.link}><button className={styles.signupBtn}>sign up</button></Link>
                    </>
                ) : (
                    isAuthenticated && displayLogin && notInPlan && <Link to={"/manage-account"} className={styles.accountLink}><img className={styles.accountSvg} src={accountSvg} ></img></Link>
                )
            }

            {!displayLogin && notInPlan && <>
                <Link className={styles.loginBtn} to="/planner">planner</Link>
                <Link className={styles.loginBtn} to="/fpl-fixture-ticker">ticker</Link>

            </>}




        </header>
    )
}

