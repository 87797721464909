import React from 'react'

import styles from './HeroPage.module.scss'
import { Header } from '../Header/Header'
import Footer from '../Footer/Footer'
import screenshot from './screenshot.webp'

import { Link } from 'react-router-dom'


import { Helmet } from "react-helmet";
import { StandardLayout } from '../StandardLayout/StandardLayout'

function HeroPage() {
    return (
        <StandardLayout
            canonicalUrl='https://fplstrat.app'
            metaDescription='The fpl planner designed for mobile! Conquer your mini league with the best planning UI, a powerful fixture ticker, and xG-based FDR!'
            metaTitle='fplstrat.app | the ultimate FPL planner!'>



            <section className={styles.mainSection}>
                <section className={styles.heroTitleSection}>
                    <h1 className={styles.heroTitle}>the <span className={styles.ultimate}>ultimate</span> fpl planner</h1>
                    <h2 className={styles.heroSubtitle}>
                        Designed for mobile, the fplstrat fpl planner helps you <span className={styles.highlight}>conquer your mini league</span>!
                        Featuring a <span className={styles.highlight}>smooth planning UI</span>,
                        a <span className={styles.highlight}>powerful fixture ticker</span>, a <span className={styles.highlight}>smart stat overview for players</span> and
                        <span className={styles.highlight}> xG based FDR</span>,
                        click start planning now to <span className={styles.highlight}>get prepared for future gameweeks</span>!

                    </h2>



                    <div className={styles.btnContainer}>
                        <Link className={`${styles.startPlanningBtn} plausible-event-name=click+start+planning`} to="/planner" >{"->"} start planning!</Link>

                    </div>


                </section>
                <section className={styles.heroImageSection}>

                    <img className={styles.screenshot} srcSet="screenshot-sm.webp 224w, screenshot.webp 618w"
                        sizes="(min-width: 450px) 618px, 224px"
                        alt="fpl-transfer-planner screenshot" />
                </section>
            </section>


        </StandardLayout>
    )
}

export default HeroPage