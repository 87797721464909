


// Global error handler for catching runtime errors
window.onerror = function (message, source, lineno, colno, error) {

    fetch('/api/log-error/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            type: 'error',
            message,
            source,
            lineno,
            colno,
            stack: error?.stack,
        }),
    });
};

// Global promise rejection handler
window.onunhandledrejection = function (event) {
    fetch('/api/log-error/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            type: 'promiseRejection',
            event: event,
        }),
    });


};