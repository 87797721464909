import React, { useEffect } from 'react'
import { StandardLayout } from '../StandardLayout/StandardLayout'
import FixtureTicker from '../FixtureTicker/FixtureTicker'
import styles from './FixtureTickerPage.module.scss'
import { Fixture } from '../../../../domain/model'
import { getFutureFixtures } from '../../fpl/api'
import { ToastContext } from '../../Hooks/ToastContext'
import Loading from '../Loading/Loading'
import { FDRContext } from '../../Hooks/FDRContext'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function FixtureTickerPage() {
  const [fixtures, setFixtures] = React.useState<Fixture[]>([])
  const [nextGameweek, setNextGameweek] = React.useState<number>(1)
  const [loading, setLoading] = React.useState<boolean>(true)

  const { createToast } = React.useContext(ToastContext)
  const { setTeams } = React.useContext(FDRContext)

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    try {
      setLoading(true)
      const data = await getFutureFixtures()
      setFixtures(data.fixtures)
      setNextGameweek(data.nextEventId)
      setTeams(data.teams)

    } catch (error) {
      createToast("negative", "Error getting fixture data :( Please try again later.")
    }
    setLoading(false)
  }

  return (
    <StandardLayout
      smallPadding={true}
      canonicalUrl='https://fplstrat.app/fpl-fixture-ticker'
      metaDescription='Get ahead of your rivals using the fplstrat fpl fixture ticker! Switch between the official and xG based FDR to spot trends before anyone else!'
      metaTitle='fplstrat.app | fpl fixture ticker'>
  
      {loading ? <Loading text='getting fixtures...' /> :
        <section className={styles.fixtureTickerSection}>
          <h1 className={styles.tickerHeading}>FPL Fixture Ticker</h1>
          <p className={styles.subHeading}><Link to={"/planner"}>Click here</Link> to start planning your team!</p>
          <FixtureTicker fixtures={fixtures} fromGw={nextGameweek} />
        </section>}


    </StandardLayout>

  )
}
