

export function usePlausibleEvent() {
    const plausible = (window as any).plausible;

    const recordEvent = (eventName: string) => {
        if (plausible) {
            plausible(eventName);
        }
    }

    return { recordEvent }
}