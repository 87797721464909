import React from 'react'
import styles from './CloseWindowButton.module.scss'

import closeBtn from './cross-svgrepo-com.svg'

type CloseWindowButtonProps = {
    closeWindowFunction: Function
}

export default function CloseWindowButton({ closeWindowFunction }: CloseWindowButtonProps) {
    return (
        <img className={styles.closeBtn} src={closeBtn} onClick={() => closeWindowFunction()} alt="" />
    )
}
