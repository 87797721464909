import React from 'react'
import styles from './LoginPage.module.scss'
import { Header } from '../Header/Header'
import Footer from '../Footer/Footer'
import { AuthContext } from '../../Hooks/AuthContext'
import { Helmet } from 'react-helmet'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import GoogleOAuth from '../GoogleOAuth/GoogleOAuth'
import { StandardLayout } from '../StandardLayout/StandardLayout'

type FormValues = {
  email: string,
  password: string
}

type FormErrors = {
  email?: string,
  password?: string
}

function LoginPage() {
  const initialValues = {
    email: "",
    password: "",
    remember: false
  }

  const { isAuthenticated, isAuthenticating, logIn } = React.useContext(AuthContext)

  const [formValues, setFormValues] = React.useState(initialValues)
  const [formErrors, setFormErrors] = React.useState<FormErrors>({})
  const [isSubmit, setIsSubmit] = React.useState(false)
  const [authError, setAuthError] = React.useState("")

  const location = useLocation()
  const navigate = useNavigate()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const error = await logIn(formValues.email, formValues.password, formValues.remember)
      if (error !== "") {
        setAuthError(error)
      } else {
        if (location.state?.from) {
          navigate(location.state.from, { replace: true })
        } else {
          navigate("/planner", { replace: true })
        }
      }
    }
    setIsSubmit(true);
  }

  const validate = (values: FormValues) => {
    const errors: FormErrors = {}
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;

    if (!values.email) {
      errors.email = "Email is required"
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid email address"
    }

    if (!values.password) {
      errors.password = "Password is required"
    } else if (values.password.length < 8) {
      errors.password = "Password must be at least 8 characters"
    } else if (values.password.length > 20) {
      errors.password = "Password must be less than 20 characters"
    }

    return errors;
  }



  return (
    <StandardLayout canonicalUrl='https://fplstrat.app/login' metaDescription='log in to your fplstrat.app account!' metaTitle='fplstrat.app | log in'>
      <section className={styles.loginSection}>
        <h1 className={styles.title}>Login</h1>
        <div className={styles.oAuthContainer}>
          <GoogleOAuth login={true} />
          <hr />
        </div>

        <p>or</p>


        <form className={styles.form} onSubmit={handleSubmit}>

          <label htmlFor="email">e-mail: </label>
          <input onChange={handleChange} className={styles.textInput} name='email' id='email' type="text" placeholder="e-mail" />
          {formErrors.email && <p className={styles.error}>{formErrors.email}</p>}

          <label htmlFor="password">password: </label>
          <input onChange={handleChange} className={styles.textInput} name='password' id='password' type="password" placeholder="password" />
          {formErrors.password && <p className={styles.error}>{formErrors.password}</p>}


          <div className={styles.checkboxContainer}>
            <input onChange={handleChange} type="checkbox" name="remember" id="remember" />
            <label htmlFor="remember">remember me</label>
          </div>

          <button>{isAuthenticating ? "logging in..." : "login"}</button>
          {authError && <p className={styles.error}>{authError}</p>}
        </form>



      </section>


    </StandardLayout>
  )
}

export default LoginPage