import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Header } from "../Header/Header";
import styles from "./ErrorFallback.module.scss";
import { useEffect } from "react";

type ErrorProps = {
  error: Error;
  resetErrorBoundary: () => void;
}

export function ErrorFallback({ error, resetErrorBoundary }: ErrorProps) {
  

  useEffect(() => {
    const logError = async () => {
      try {
        await fetch('/api/log-error/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name: error.name, message: error.message, stack: error.stack }),
        });
      } catch (err) {
        console.error('Failed to log error:', err);
      }
    };

    logError();
  }, [error]);
  
  return (
    <>
      <section className={styles.pageNotFoundSection}>
        <Header notInPlan={true}></Header>

        <section className={styles.main}>
          {error.name && <h1 className={styles.pageNotFoundHeading}>{error.name}</h1>}
          <p>{error.message}</p>
          <Link onClick={resetErrorBoundary} to="/" replace className={styles.link}>return home</Link>
        </section>

      </section>
    </>
  );
}