import React from 'react'
import styles from './Loading.module.scss'
import loadingSpinner from "./loading.svg"
export default function Loading({ text }: { text: string }) {
  return (
    <div className={styles.spinner}>
      <img src={loadingSpinner} alt="loading" />
      <p className={styles.loadingText}>{text}</p>
    </div>
  )
}
