import { useContext } from "react";
import { AuthContext } from "../../Hooks/AuthContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import LoginPage from "../LoginPage/LoginPage";
import Loading from "../Loading/Loading";

import styles from "./ProtectedRoutes.module.scss";

export function ProtectedRoutes() {
    const { isAuthenticated, isAuthenticating } = useContext(AuthContext);
    const location = useLocation();

    if (isAuthenticating) {
        return <div className={styles.loadingContainer}>
            <Loading text="authenticating" />
        </div>
    }

    return isAuthenticated ? (<Outlet />) : <Navigate to="/login" replace state={{from: location}}/>
}