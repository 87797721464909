import { Helmet } from 'react-helmet'
import Footer from '../Footer/Footer'
import { Header } from '../Header/Header'
import styles from './Contact.module.scss'
import { StandardLayout } from '../StandardLayout/StandardLayout'


export function Contact() {
    return (
        <>


            <StandardLayout canonicalUrl='https://fplstrat.app/contact' metaDescription='how to contact us at fplstrat.app!' metaTitle='fplstrat.app | contact'>




                <section className={styles.main}>
                    <h1 className={styles.contactHeading}>Contact Us</h1>
                    <p className={styles.subheading}>for any queries or feedback, send an email to  </p>
                    <p className={styles.contact}>hello@fplstrat.app</p>
                    <p className={styles.subheading}>or hit me up on X!</p>
                    <p className={styles.contact}>
                        <a href='https://twitter.com/fplstratapp?ref_src=twsrc%5Etfw' className={styles.contact}>@fplstratapp</a>
                    </p>

                </section>


            </StandardLayout>



        </>
    )
}