import styles from './RegisterPage.module.scss'
import { Header } from '../Header/Header'
import Footer from '../Footer/Footer'
import React, { useEffect, useState } from 'react'
import { AuthContext } from '../../Hooks/AuthContext'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import googleLogo from './google.png'
import GoogleOAuth from '../GoogleOAuth/GoogleOAuth'
import { StandardLayout } from '../StandardLayout/StandardLayout'

type FormValues = {
  email: string,
  password: string,
  confirmPassword: string,
  terms: boolean,
  receiveupdates: boolean

}

type FormErrors = {
  email?: string,
  password?: string,
  confirmPassword?: string,
  terms?: string

}

function RegisterPage() {

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
    receiveupdates: false
  }

  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [authError, setAuthError] = useState("")

  const [isRegistering, setIsRegistering] = useState(false)



  const { isAuthenticating, register, oAuth } = React.useContext(AuthContext)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "terms") {
      setFormValues({ ...formValues, [name]: !formValues.terms })
    } else if (name === "receiveupdates") {
      setFormValues({ ...formValues, [name]: !formValues.receiveupdates })
    } else {
      setFormValues({ ...formValues, [name]: value })
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setIsRegistering(true)
      const error = await register(formValues.email, formValues.password, formValues.receiveupdates)
      error !== "" && setAuthError(error)

    }
    setIsRegistering(false)
    setIsSubmit(true);
  }



  const validate = (values: FormValues) => {
    const errors: FormErrors = {}
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;

    if (!values.email) {
      errors.email = "Email is required"
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid email address"
    }

    if (!values.password) {
      errors.password = "Password is required"
    } else if (values.password.length < 8) {
      errors.password = "Password must be at least 8 characters"
    } else if (values.password.length > 15) {
      errors.password = "Password must be less than 15 characters"
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password"
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = "Passwords do not match!"
    }

    if (!values.terms) {
      errors.terms = "You must agree to our privacy policy"
    }

    return errors
  }

  return (
    <StandardLayout canonicalUrl='https://fplstrat.app/register' metaDescription='create an account for fplstrat.app!' metaTitle='fplstrat.app | sign up'>

      <section className={styles.signUpSection}>
        <h1 className={styles.title}>Sign Up</h1>
        <div className={styles.oAuthContainer}>
          <GoogleOAuth login={false} />
          <hr />
        </div>

        <p>or</p>


        <form className={styles.form} onSubmit={handleSubmit}>

          <label htmlFor="email">e-mail: </label>
          <input className={styles.textInput} type="text" name='email' id='email' placeholder="e-mail" value={formValues.email} onChange={handleChange} />
          {formErrors.email && (<p className={styles.error}>{formErrors.email}</p>)}

          <label htmlFor="password">password: </label>
          <input className={styles.textInput} type="password" name='password' id='password' placeholder="password" value={formValues.password} onChange={handleChange} />
          {formErrors.password && (<p className={styles.error}>{formErrors.password}</p>)}

          <label htmlFor="confirmPassword">confirm password: </label>
          <input className={styles.textInput} type="password" name='confirmPassword' id='confirmPassword' placeholder="confirm password" value={formValues.confirmPassword} onChange={handleChange} />
          {formErrors.confirmPassword && (<p className={styles.error}>{formErrors.confirmPassword}</p>)}

          <div className={styles.checkboxContainer}>
            <input type="checkbox" name="terms" id="terms" checked={formValues.terms} onChange={handleChange} />
            <label htmlFor="terms" className={styles.label}>i have read and agree to the <a href="/privacy-policy.html">privacy policy</a></label>
          </div>

          {formErrors.terms && (<p className={styles.error}>{formErrors.terms}</p>)}

          <div className={styles.checkboxContainer}>
            <input type="checkbox" name="receiveupdates" id="receiveupdates" checked={formValues.receiveupdates} onChange={handleChange} />
            <label htmlFor="receiveupdates">send me fplstrat.app news and feature updates</label>
          </div>

          <button className="plausible-event-name=click+sign+up">{isRegistering ? "singing up ..." : "sign up"}</button>
          {authError && <p className={styles.error}>{authError}</p>}
        </form>

      </section>





    </StandardLayout>
  )  
}


export default RegisterPage