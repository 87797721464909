import CloseWindowButton from "../CloseWindowButton/CloseWindowButton"
import styles from "./Toast.module.scss"

type ToastProps = {
    toastType: "positive" | "negative" | "neutral"
    msg: string
    closeWindowFunction: (id: number) => void
    id: number
}

export function Toast({ toastType, msg, closeWindowFunction, id }: ToastProps) {
    const toastTypeToStyling = {
        "positive": styles.positive,
        "negative": styles.negative,
        "neutral": styles.neutral
    }
    return <div className={`${styles.toast} ${toastTypeToStyling[toastType]}`}>

        <p>{msg}</p>
        <CloseWindowButton closeWindowFunction={() => closeWindowFunction(id)} />


    </div>
}