
import { Fixture, Plan, Player } from "../../../domain/model"


const baseUrl = ""

export const planPrefix = "xxx"


export async function getReplacementPlayers(playerId: number): Promise<Player[]> {
    return fetch(baseUrl + `/api/players/replacements/${playerId}`)
        .then(response => response.json()).catch(e => console.log(e))

}

export async function getPlans(csrfToken: string) {
    return fetch(baseUrl + `/api/plans/`, {
        headers: {
            'csrf-token': csrfToken
        }
    }).then(response => response.json()).catch(e => console.log(e))
}

export async function getPlan(planId: number, csrfToken: string) {
    return fetch(baseUrl + `/api/plans/${planId}/`, {
        headers: {
            'csrf-token': csrfToken
        }
    }).then(response => response.json()).catch(e => console.log(e))

}

export async function deletePlan(planId: number, csrfToken: string) {
    return fetch(baseUrl + `/api/plans/${planId}/`, {
        method: 'DELETE',
        headers: {
            'csrf-token': csrfToken
        }
    }).then(response => response.json()).catch(e => console.log(e))

}

export async function getFutureFixtures() {
    return fetch(baseUrl + `/api/fixtures/`).then(response => response.json()).catch(e => console.log(e))

}

export async function getNextGameweek() {
    return fetch(baseUrl + `/api/nextGw/`).then(response => response.json()).catch(e => console.log(e))
}


export async function createPlan(planName: string, fplId: string,  csrfToken: string, planToCopy?: string,) {
    return fetch(baseUrl + `/api/plans/`, {
        method: 'POST',
        body: JSON.stringify({ planName: planName, fplId: fplId, planId: planToCopy }),
        headers: {
            'Content-Type': 'application/json',
            'csrf-token': csrfToken
        },

    }).then(response => response.json()).catch(e => console.log(e))
}

export async function createPlanLocal(planName: string, fplId: string) {
    const planList = getPlanListLocally()
    const planId = planList.length > 0 ? planList[planList.length - 1].id + 1 : 1;
    return fetch(baseUrl + `/api/plans/x`, {
        method: 'POST',
        body: JSON.stringify({ planName: planName, fplId: fplId, planId: planId }),
        headers: {
            'Content-Type': 'application/json'
        },

    }).then(response => response.json()).catch(e => console.log(e))

}

export function savePlanLocally(plan: any) {
    try {
        const planListLocal = localStorage.getItem(planPrefix + "plans")
        const planList = planListLocal ? JSON.parse(planListLocal) : [];

        planList.push(plan)
        localStorage.setItem(planPrefix + "plans", JSON.stringify(planList))
        return true

    } catch (error) {
        return false
    }


}

export function getPlanListLocally() {
    const planListLocal = localStorage.getItem(planPrefix + "plans")
    const planList: Plan[] = planListLocal ? JSON.parse(planListLocal) : [];
    return planList;

}

export function savePlanStateLocally(plan: any) {
    const planListLocal = localStorage.getItem(planPrefix + "plans")
    const planList = planListLocal ? JSON.parse(planListLocal) : [];
    const planIndex = planList.findIndex((p: any) => p.id === plan.id)
    planList[planIndex] = plan
    const planJson = JSON.stringify(planList)
    localStorage.setItem(planPrefix + "plans", planJson)

}

export function getPlanLocally(planId: number) {
    const planListLocal = localStorage.getItem(planPrefix + "plans")
    const planList = planListLocal ? JSON.parse(planListLocal) : [];
    return planList.find((p: any) => p.id === planId)
}

export function deletePlanLocally(planId: number) {
    const planListLocal = localStorage.getItem(planPrefix + "plans")
    const planList = planListLocal ? JSON.parse(planListLocal) : [];
    localStorage.setItem(planPrefix + "plans", JSON.stringify(planList.filter((p: any) => p.id !== planId)))
}

export function minimisePlan(plan: Plan) {
    return {
        id: plan.id,
        name: plan.name,
        user_id: plan.user_id,
        fpl_team_id: plan.fpl_team_id,
        event_states: plan.event_states.map(e => {
            return {
                plan_id: e.plan_id,
                event_id: e.event_id,
                available_chips: e.available_chips?.map(c => c.id),
                active_chip: e.active_chip?.id,
                players: e.players.map(p => {
                    return { id: p.id, bought_for: p.bought_for }
                }),
                available_free_transfers: e.available_free_transfers,
                transfers_made: e.transfers_made,
                money_left: e.money_left,
                captain: e.captain,
                vice_captain: e.vice_captain,
            }
        }),
        transfers: plan.transfers.map(t => {
            return {
                plan_id: t.plan_id,
                event_id: t.event_id,
                player_in: t.player_in.id,
                player_out: t.player_out.id,
                player_out_sold_for: t.player_out_sold_for,
                player_in_bought_for: t.player_in_bought_for,
                reverse: t.reverse
            }
        })
    }
}

export async function sharePlanGlobal(plan: Plan) {

    const minimisedPlan = minimisePlan(plan)
    const planJson = JSON.stringify(minimisedPlan)



    return fetch(baseUrl + `/api/plans/share/`, {
        method: 'POST',

        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ planJson }),

    }).then(response => response.json()).catch(e => console.log(e))
}

export async function getPlayers(playerIds: number[]) {

    const idsJson = JSON.stringify(playerIds)
    return fetch(baseUrl + `/api/players/${idsJson}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },

    }).then(response => response.json()).catch(e => console.log(e))
}

export async function getPlanFromSnapshot(id: number): Promise<Plan> {
    return fetch(baseUrl + `/api/plans/share/${id}/`)
        .then(response => {

            if (response.ok) {
                return response.json()
            }

            return null

        })
        .catch(e => null)
}

export async function deleteUser(csrfToken: string, userId: string) {
    return fetch(baseUrl + `/api/auth/user/${userId}/`, {
        method: 'DELETE',
        headers: {
            'csrf-token': csrfToken
        }
    }).then(response => {
        if (response.ok) {
            return true
        }
        return false
    }
    ).catch(e => false)
}