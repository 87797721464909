import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './Components/App/App'



import './index.css'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from './Hooks/AuthContext'

import { CookieBanner } from './Components/CookieBanner/CookieBanner'
import HasChangedProvider from './Hooks/HasChangedContext'

import FDRProvider from './Hooks/FDRContext'

import { ErrorBoundary } from 'react-error-boundary'

import './reportExceptions'
import { ErrorFallback } from './Components/ErrorFallback/ErrorFallback'
import ToastProvider from './Hooks/ToastContext'


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>

    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AuthProvider>
          <HasChangedProvider>
            <FDRProvider>
              <ToastProvider>
                <App />
              </ToastProvider>

            </FDRProvider>

          </HasChangedProvider>

        </AuthProvider>
      </ErrorBoundary>
    </BrowserRouter>



  </React.StrictMode>,
)
