import React, { createContext, useContext, useEffect, useState } from 'react'
import { Navigate, redirect, useNavigate } from 'react-router-dom'
import { getPlanListLocally, minimisePlan } from '../fpl/api'

import useInterval from './useInterval'
import { usePlausibleEvent } from './usePlausibleEvent'

const baseUrl = ""

type AuthContextType = {
    isAuthenticated: boolean,
    isAuthenticating: boolean,
    csrfToken: string,
    userId: string,
    email: string,
    logIn: (email: string, password: string, remember: boolean) => Promise<string>,
    register: (email: string, password: string, receiveupdates: boolean) => Promise<string>,
    logOut: () => void,
    oAuth: () => void,
    justRegistered?: boolean,
    justVerified?: boolean,
    emailVerified: boolean

}

export const AuthContext = createContext<AuthContextType>({
    isAuthenticated: false,
    isAuthenticating: true,
    csrfToken: "",
    userId: "",
    email: "",
    logIn: async () => "",
    logOut: () => { },
    register: async () => "",
    oAuth: () => { },
    emailVerified: false
})



export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isAuthenticating, setIsAuthenticating] = useState(true)
    const [userId, setUserId] = useState("")
    const [email, setEmail] = useState("")
    const [csrfToken, setCsrfToken] = useState("")
    const { recordEvent } = usePlausibleEvent()
    const [justRegistered, setJustRegistered] = useState(false)

    const [justVerified, setJustVerified] = useState(false)
    const [emailVerified, setEmailVerified] = useState(false)


    const navigate = useNavigate()

    const checkAuth = async () => {

        try {
            const response = await fetch(baseUrl + "/api/auth/check", {
                credentials: "include",
                headers: {
                    'csrf-token': csrfToken
                }
            })

            if (response.status === 200) {
                setIsAuthenticated(true)
                const data = await response.json()
                setUserId(data.userId)
                setEmail(data.email)
                setCsrfToken(data.csrfToken)
                setJustVerified(data.justVerified)
                setEmailVerified(data.emailVerified)

                if (data.justRegistered === true) {
                    setJustRegistered(true)
                    await uploadPlan(data.csrfToken)
                }



            } else {

                setIsAuthenticated(false)
            }



        } catch (error) {
            setIsAuthenticated(false)
        }

        setIsAuthenticating(false)
    }

    useEffect(() => {
        const intervalId = setInterval(checkAuth, 1000 * 60 * 30)

        try {
            checkAuth()
        } catch (e: any) {

        }


        return () => {
            clearInterval(intervalId)
        }
    }, [
    ])

    const oAuth = async () => {
        const response = await fetch("/api/auth/oauth", { method: "POST" })
        const data = await response.json()
        window.location.href = data.url
    }

    const uploadPlan = async (csrf: string) => {
        const localPlans = getPlanListLocally()
        let planJson = ""

        if (localPlans.length > 0) {
            const plan = minimisePlan(localPlans[0])
            planJson = JSON.stringify(plan)
        } else {
            return true
        }

        const response = await fetch(baseUrl + "/api/plans/upload", {
            credentials: "include",
            method: "POST",
            headers: {
                'csrf-token': csrf,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ planJson })
        })

        if (response.status === 204) {
            return true
        }

        return false

    }


    const register = async (email: string, password: string, receiveupdates: boolean) => {
        let authError = ""
        setIsAuthenticating(true)
        try {



            const response = await fetch(baseUrl + "/api/auth/register", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, receiveupdates })

            })

            if (response.status === 201) {
                const data = await response.json()
                setIsAuthenticated(true)
                setUserId(data.userId)
                setEmail(data.email)
                setCsrfToken(data.csrfToken)

                await uploadPlan(data.csrfToken)
                setJustRegistered(true)

                recordEvent("signup")

                navigate("/planner", { replace: true })

            } else {
                authError = await response.json().then(data => data.error)
            }

        } catch (error: any) {
            authError = "Something went wrong! Please try again later."

        }
        setIsAuthenticating(false)
        return authError
    }

    const logIn = async (email: string, password: string, remember: boolean) => {
        let authError = ""
        setIsAuthenticating(true)
        try {
            const response = await fetch(baseUrl + "/api/auth/login", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({ email, password, remember })

            })

            if (response.status === 200) {
                setIsAuthenticated(true)
                const data = await response.json()
                setUserId(data.userId)
                setEmail(data.email)
                setCsrfToken(data.csrfToken)
                setJustVerified(data.justVerified)
                setEmailVerified(data.emailVerified)




            } else {
                authError = await response.json().then(data => data.error)
            }

        } catch (error) {
            authError = "Something went wrong! Please try again later."
        }
        setIsAuthenticating(false)
        return authError

    }

    const logOut = async () => {
        setIsAuthenticating(true)
        try {
            const response = await fetch(baseUrl + "/api/auth/logout", {
                method: "POST",
                credentials: "include",
                headers: {
                    'csrf-token': csrfToken
                }
            })

            if (response.status === 200) {
                localStorage.setItem("cookieSet", "false")

                navigate("/", { replace: true })
                window.location.reload()
                return
            }



        } catch (error) {

        }
        setIsAuthenticating(false)
    }



    return (
        <AuthContext.Provider value={{ isAuthenticated, justRegistered, userId, oAuth, register, isAuthenticating, logIn, logOut, csrfToken, email, emailVerified, justVerified }}>
            {children}
        </AuthContext.Provider>
    )
}